export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-home',
            badge: {
                variant: 'primary'
            }
        },
        {
            name: 'Transactions',
            url: '/transaction',
            icon: 'icon-list',
            children: [
                {
                    name: 'Asset Pool Distribution',
                    url: '/asset-pool-distribution',
                    icon: 'fa fa-pie-chart',
                },
                {
					name: 'Inventory Session',
					url: '/inventory-session',
					icon: 'fa fa-cubes'
				},
                {
                    name: 'Dispatch',
                    url: '/dispatch',
                    icon: 'fa fa-truck'
                },
                {
                    name: 'Receipt',
                    url: '/receipt',
                    icon: 'fa fa-map-pin'
                }
            ]
        },
        {
            name: 'Reports',
            url: '/reports',
            icon: 'fa fa-line-chart',
            children: [
                {
                    name: 'Asset Accountability',
                    url: '/asset-accountability',
                    icon: 'fa fa-list-alt',
                },
                {
                    name: 'Discrepancy Report',
                    url: '/discrepancy-reports',
                    icon: 'fa fa-flag',
                },
            ]
        }
    ]
};
